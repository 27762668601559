/* Globals Vue, axios */
const Contact = (selector) => {
  new Vue({
    el: selector,
    data () {
      return {
        formDisabled: false,
        showThankYou: false,
        email: '',
        fname: '',
        lname: '',
        company: '',
        jobtitle: '',
        zip: '',
        message: '',
        issuetype: ''
      }
    },
    methods: {
      getCookieValue(name) {
        const regex = new RegExp(`(^| )${name}=([^;]+)`)
        const match = document.cookie.match(regex)
        if (match) {
          return match[2]
        }
      },
      async validateForm () {
        if (!this.formDisabled) {
          this.formDisabled = true
          let contactResponse = {}
          try {
            contactResponse = await axios.post('/.netlify/functions/hubspot-contact', {
              email: this.email,
              firstname: this.fname,
              lastname: this.lname,
              company: this.company,
              jobtitle: this.jobtitle,
              zip: this.zip,
              message: this.message,
              issuetype: this.issuetype,
              pageURL: window.location.href,
              pageName: document.getElementsByTagName("title")[0].innerText,
              hubspotutk: this.getCookieValue('hubspotutk')
            })
          } catch {
            contactResponse.status = 500
          }

          if (contactResponse.status === 200) {
            this.showThankYou = true
          } else {
            this.formDisabled = false
          }
        }
      }
    }
  })
}
export default Contact;
