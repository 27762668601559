import Email from './lib/email.js'
import Nav from './lib/nav.js'
import Share from './lib/share.js'
import Sticky from './lib/sticky.js'
import Modal from './lib/modal.js'
import Violator from './lib/violator.js'
import Contact from './lib/contact.js'

const email = Email('.polymedco-email')
const nav = Nav('.site-nav')
const share = Share('.share')
const contact = Contact('#contact')
const sticky = Sticky('.sticky-wrapper')
const modal = Modal('.modal-open')
// const violator = Violator('#violator')

function loop () {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  window.requestAnimationFrame(loop)
}
window.requestAnimationFrame(loop)

export default Share;
