import utils from './utils.js'

const Sticky = (selector) => {
  const sticky = document.querySelector(selector)
  if (!utils.getParam('screenshot')) {
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle("sticky", e.intersectionRatio < 1), { threshold: [1] }
    )
    observer.observe(sticky)
  } else {
    sticky.classList.add('screenshot')
  }
}

export default Sticky;
