const Nav = (selector) => {
  const siteNav = document.querySelector(selector)
  if (siteNav) {
    // Open submenus when tabbing/hovering
    const navsubmenus = siteNav.querySelectorAll('.hassubmenu > .label')
    navsubmenus.forEach(a => {
      a.addEventListener('click', (e) => {
        e.preventDefault()
        // Prevent sticky nav on click, leaving class remnant
        Array.from(navsubmenus).map(menu => {
          menu.nextElementSibling.classList.remove('active')
        })
      })
      a.addEventListener('focus', e => {
        e.target.nextElementSibling.classList.add('active')
        e.preventDefault()
      })
    })
    // Close Submenus when tabbing elsewhere
    const navnosubmenus = document.querySelectorAll('.logolink, .nosubmenu, .contact-us')
    if (navnosubmenus) {
      Array.from(navnosubmenus).map(menu => {
        menu.addEventListener('focus', e => {
          Array.from(navsubmenus).map(menu => {
            menu.nextElementSibling.classList.remove('active')
          })
        })
      })
    }
    // Highlight navigation menu for current page
    siteNav.querySelectorAll('a').forEach(a => {
      if (a.getAttribute('href') === document.location.pathname) {
        while (a.parentNode) {
          a = a.parentNode
          if (a.tagName === 'LI') {
            a.classList.add('current')
          }
        }
      }
    })

    const button = siteNav.querySelector('.menu-button')
    button.addEventListener('click', (e) => {
      e.preventDefault()
      siteNav.classList.toggle('open')
      document.body.classList.toggle('killscroll')
    })
  }
}

export default Nav;
